import React from "react";
import styled from "styled-components";
import { FaUserTie } from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FaPhone, FaAt } from "react-icons/fa";

const Employees = ({ langCode }) => {
    const positions = {
        en: {
            ceo: "CEO",
            cto: "CTO",
            leadDev: "Lead Developer",
            seniorConsultant: "Senior Consultant, Delivery Manager, Sales",
            seniorDeveloper: "Senior Developer",
            Developer: "Developer",
            consultant: "Consultant",
        },
        no: {
            ceo: "Daglig leder",
            cto: "Teknologiansvarlig",
            leadDev: "Lead utvikler",
            seniorConsultant: "Senior konsulent, leveranseansvarlig, salg",
            seniorDeveloper: "Senior utvikler",
            Developer: "Utvikler",
            consultant: "Konsulent",
        },
    };
    const descriptions = {
        en: {
            henrik:
                "Henrik holds a degree in civil engineering with a specialization in production technology. He is deeply passionate about maintaining good, long-lasting relationships with customers and delivering user-friendly solutions that serve the customer. If our customers are happy, Henrik is happy. Other things that make Henrik happy are cross-country skiing, sailing, exercising and spending time in nature.",
            geir:
                "With an education in economics and extensive accounting experience, Geir is good with numbers. He is passionate about ensuring that customers’ systems function as they should and that they get the numbers they need for reporting. When he is not assisting customers with their accounting systems and calculations, you will find Geir hiking in the mountains, fishing or relaxing in his cabin.",
            fjolnir:
                "Jón Fjölnir is a systems analyst and Dynapro’s “altmuligmann”, as we say in Norwegian. Whether he is creating new solutions, consulting customers, or upgrading a system—delivering high quality service and products to customers is what motivates him. In his spare time, Jón Fjölnir enjoys learning new languages, playing and watching football, and spending time with family and friends.",
            markus:
                "Markus is a self-made ERP specialist. Six years ago, he started designing layouts for reports, and from there began taking on small programming tasks. Today, Markús is a senior developer and is passionate about developing creative solutions that help customers reach their goals. When not in the office, it is reasonable to assume that Markús is studying programming, reading the latest on robot technology, travelling or watching a classic movie.",
            hermann:
                "Hermann has a BSc in Computer Science and a BA in English. Writing quality code that is clean, readable, maintainable and extensible is what Hermann is all about. The result of his enthusiasm? Better solutions, fewer problems and lower costs for customers. When he isn’t knee deep in code, you’ll most likely find Hermann building Lego with his sons, enjoying the outdoors with his family or reading up on various programming topics.",
            hans:
                "Hans Jørgen has over 20 years experience as a Dynamics NAV developer and consultant. He has worked on vertical solutions focused on the automotive and construction industries and has created tailor made solutions for numerous customers. His experience and extensive knowledge of ERP systems and business processes enable him to both design and develop quality solutions for our customers.",
            otto:
                "Otto Munthe has a PhD in Fluid Mechanics for which he used object oriented programming to simulate flow. He has since worked for over ten years in software development and consulting. When he isn’t working, Otto likes to exercise or ride his motorbike. He also spends a lot of time out at sea on his boat..",
            tien:
                'Tien has a master\'s degree in finance and accounting and has completed intensive Microsoft University Business Central training. With experience as an auditor, he has a solid foundation in financial data analysis and reporting. Tien assists customers in streamlining business processes and optimizing the use of their ERP systems. When not working, Tien enjoys playing table tennis and can often be found practicing his "loop" swing with a racket in hand.',
            maryam:
                "With a degree in Information Technology, Maryam is passionate about programming. Before joining the team at Dynapro, she worked as a data analyst. Her current role involves designing custom made reports for customers. In her free time, Maryam likes to be active. She runs a lot and enjoys biking with her family.",
        },
        no: {
            henrik:
                "Henrik er utdannet sivilingeniør med spesialisering i produksjonsteknologi. Han er veldig opptatt av å opprettholde gode, langvarige forhold til kunder og levere brukervennlige løsninger som betjener kunden. Hvis kundene hans er fornøyde, er Henrik fornøyd. Andre ting som gjør Henrik lykkelig er langrenn, seiling, trening og tid i naturen.",
            geir:
                "Med utdannelse som siviløkonom og omfattende regnskapserfaring er Geir god med tall. Han er veldig opptatt av å sikre at kundenes systemer fungerer som de skal og at de får tallene de trenger for rapportering. Når han ikke hjelper kunder med deres regnskapssystemer og beregninger, vil du finne Geir på hytta, enten på tur, eller med en fiskestang.",
            fjolnir:
                "Jón Fjölnir er systemanalytiker og Dynapros «altmuligmann». Enten han skaper nye løsninger, konsulterer kunder eller oppgraderer et system - så er det å levere tjenester og produkter av høy kvalitet til kundene det som motiverer han. På fritiden liker Jón Fjölnir å lære nye språk, spille og se på fotball og tilbringe tid med familie og venner.",
            markus:
                "Markus er en egenprodusert ERP-spesialist. For seks år siden begynte han å designe layouter for rapporter, og derfra begynte han å ta på seg små programmeringsoppgaver. I dag er Markús seniorutvikler og brenner for å utvikle kreative løsninger som hjelper kundene å nå sine mål. Når han ikke er på kontoret, er det rimelig å anta at Markús studerer programmering, leser det siste om robotteknologi, reiser eller ser på en klassisk film.",
            hermann:
                "Hermann har en bachelorgrad i informatikk og en bachelorgrad i engelsk. Å skrive kvalitetskode som er ren, lesbar, vedlikeholdbar og utvidbar kjennetegner Hermann. Resultatet av hans entusiasme er: Bedre løsninger, færre problemer og lavere kostnader for kundene. Når han ikke er dypt inni koden, da bygger han Lego sammen med sønnene sine, koser seg utendørs med familien eller leser forskjellige programmeringsemner.",
            hans:
                "Hans Jørgen har over 20 års erfaring som Dynamics NAV-utvikler og konsulent. Han har jobbet med vertikale løsninger fokusert på bil- og anleggsindustrien, samt laget skreddersydde løsninger for en rekke kunder. Hans lange erfaring og omfattende kunnskap om ERP-systemer og forretningsprosesser gjør at han både kan designe og utvikle kvalitetsløsninger for våre kunder.",
            otto:
                "Otto Munthe har en doktorgrad i fluidmekanikk hvor han brukte objektorientert programmering for å simulere flyt. Han har siden jobbet i over ti år innen programvareutvikling og rådgivning. Når han ikke jobber, liker Otto å trene eller kjøre motorsykkel. Han tilbringer også mye tid på sjøen med båten sin.",
            tien:
                "Tien har en mastergrad i finans og regnskap og har fullført ett intensivt Microsoft University Business Central utdanning. Med efaring som revisor, har ham et solid grunnlag innen finansiell dataanalyse og rapportering. Tiens hovedansvar er å bistå kunder i å effektivisere forretningsprosesser og optimalisere bruken av deres ERP-systemer. Når han ikke jobber, liker Tien å spille bordtennis og kan ofte bli funnet mens han øver sin «loop» sving med en racket i hånden.",
            maryam:
                "Med utdannelse i informasjonsteknologi er Maryam lidenskapelig opptatt av programmering. Før hun begynte i teamet hos Dynapro, jobbet hun som dataanalytiker. I sin nåværende rolle er hennes hovedansvar å lage skreddersydde rapporter for kunder. På fritiden liker Maryam å være aktiv. Hun løper mye og liker å sykle med familien.",
        },
    };
    const localizedPositions = positions[langCode];
    const localizeDescriptions = descriptions[langCode];
    const images = useStaticQuery(graphql`
        query {
            fjolnir: file(
                relativePath: { eq: "images/employees/fjolnir.jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            geir: file(relativePath: { eq: "images/employees/geir.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            henrik: file(relativePath: { eq: "images/employees/henrik.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            hermann: file(
                relativePath: { eq: "images/employees/hermann.jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            markus: file(relativePath: { eq: "images/employees/markus.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            hans: file(relativePath: { eq: "images/employees/hans.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            otto: file(relativePath: { eq: "images/employees/otto.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
            maryam: file(relativePath: { eq: "images/employees/maryam.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 200, height: 200, formats: [WEBP])
                }
            }
        }
    `);

    const emplyeeInfo = [
        {
            name: "Geir Støen",
            position: localizedPositions.ceo,
            phone: "+47 901 31 250",
            email: "geir@dynapro.no",
            image: images.geir,
            description: localizeDescriptions.geir,
            active: true,
        },
        {
            name: "Jón Fjölnir Albertsson",
            position: localizedPositions.cto,
            phone: "+47 488 49 696",
            email: "fjolnir@dynapro.no",
            image: images.fjolnir,
            description: localizeDescriptions.fjolnir,
            active: true,
        },
        {
            name: "Henrik Hella",
            position: localizedPositions.seniorConsultant,
            phone: "+47 957 34 498",
            email: "henrik@dynapro.no",
            image: images.henrik,
            description: localizeDescriptions.henrik,
            active: true,
        },
        {
            name: "Hermann Ragnarsson",
            position: localizedPositions.leadDev,
            phone: "+47 404 41 638",
            email: "hermann@dynapro.no",
            image: images.hermann,
            description: localizeDescriptions.hermann,
            active: true,
        },
        {
            name: "Markús Víðir Fjölnisson",
            position: localizedPositions.seniorDeveloper,
            phone: "+47 919 05 410",
            email: "markus@dynapro.no",
            image: images.markus,
            description: localizeDescriptions.markus,
            active: false,
        },
        {
            name: "Hans Jørgen Lachman",
            position: localizedPositions.seniorDeveloper,
            phone: "+47 909 72 310",
            email: "hans@dynapro.no",
            image: images.hans,
            description: localizeDescriptions.hans,
            active: true,
        },
        {
            name: "Otto Munthe",
            position: localizedPositions.Developer,
            email: "otto@dynapro.no",
            phone: "+47 92 80 35 53",
            image: images.otto,
            description: localizeDescriptions.otto,
            active: true,
        },
        {
            name: "Maryam Fallah",
            position: localizedPositions.Developer,
            email: "maryam@dynapro.no",
            image: images.maryam,
            description: localizeDescriptions.maryam,
            active: true,
        },
    ];
    return (
        <StyledSection>
            {emplyeeInfo
                .filter(emp => emp.active)
                .map((node, index) => {
                    return (
                        <StyledEmployee key={index}>
                            <ImageWrapper>
                                {node.image ? (
                                    <GatsbyImage
                                        image={
                                            node.image.childImageSharp
                                                .gatsbyImageData
                                        }
                                        alt={node.name}
                                    />
                                ) : (
                                    <FaUserTie className="noImage" />
                                )}
                            </ImageWrapper>
                            <h4>{node.name}</h4>
                            <p style={{ marginBottom: "0.4rem" }}>
                                {node.position}
                            </p>
                            <p
                                style={{
                                    color: "black",
                                    fontWeight: "normal",
                                    marginTop: "0.8rem",
                                    maxWidth: "700px",
                                    margin: "0.8rem auto 0.8rem auto",
                                }}
                            >
                                {node.description}
                            </p>
                            {node.phone && (
                                <span>
                                    <FaPhone />
                                    <a
                                        href={`tel:${node.phone}`}
                                        style={{
                                            display: "inline",
                                            marginLeft: "0.3rem",
                                        }}
                                    >
                                        {node.phone}
                                    </a>
                                    <br />
                                </span>
                            )}
                            <FaAt
                                style={{
                                    marginLeft: "1rem",
                                    marginTop: "1rem",
                                }}
                            />
                            <a
                                href={`mailto:${node.email}`}
                                style={{
                                    display: "inline",
                                    marginLeft: "0.3rem",
                                }}
                            >
                                {node.email}
                            </a>
                        </StyledEmployee>
                    );
                })}
        </StyledSection>
    );
};

export default Employees;

const StyledSection = styled.section`
    padding: 0;
    margin-top: 0;
    @media (max-width: 576px) {
        margin-top: 1rem;
    }
`;

const StyledEmployee = styled.article`
    text-align: center;
    margin-bottom: 4rem;
    & .noImage {
        height: 100%;
        width: 100%;
        padding: 30px 20px 10px 20px;
        vertical-align: bottom;
        margin: 0;
        background-color: var(--primaryColor);
        border-radius: 50%;
    }
    & h4 {
        letter-spacing: 0;
        margin-top: 5px;
        margin-bottom: 0px;
        font-weight: bold;
    }
    & a {
        text-decoration: none;
        color: var(--primaryColor);
        font-weight: bold;
        display: block;
    }
    & p {
        color: var(--mainGrey);
        margin-top: 0;
        font-weight: bold;
    }
`;
const ImageWrapper = styled.div`
    display: inline-block;
    color: var(--offWhite);
    text-align: center;
    width: 100%;
    max-width: 200px;
    & img {
        border-radius: 50%;
    }
`;
